import { FormHTMLAttributes, forwardRef } from "react"

import { Stack, Typography, TextField } from "@mui/material"
import { Controller, useFormContext, useWatch } from "react-hook-form"
import * as yup from "yup"

import {
  Prize,
  PrizeDelivery,
  Machine,
  PrizeFee,
  PrizeBoothCategory,
  PutPrizeMonthlyPlanRequest,
} from "src/api/models"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import { round } from "src/utils"
import {
  getExpectedSalesPrice,
  getExpectedPrizePdpb,
} from "src/utils/prizeMonthly"

export type PrizeMonthlyFormValues = {
  expectedCapturePrice: PutPrizeMonthlyPlanRequest["expectedCapturePrice"]
  expectedConsumptionRate?: Exclude<
    PutPrizeMonthlyPlanRequest["expectedConsumptionRate"],
    undefined
  >
  numOfBooths?: Exclude<PutPrizeMonthlyPlanRequest["numOfBooths"], undefined>
  boothCategory?: PutPrizeMonthlyPlanRequest["boothCategory"]
  machine?: PutPrizeMonthlyPlanRequest["machine"]
  feeId?: number
}
export const getPrizeMonthlyFormValidationSchema = (
  unitPriceJpy?: number,
): yup.ObjectSchema<PrizeMonthlyFormValues> =>
  yup.object().shape({
    expectedCapturePrice: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("正の値を入力してください。")
      .required("必須です")
      .test(
        "high",
        "1個獲得金額のP/Oが40％よりも上回っています。1個獲得金額を上げてください。",
        (value) => {
          if (!value || !unitPriceJpy) return true
          if (value < unitPriceJpy / 0.4) return false
          return true
        },
      )
      .test(
        "low",
        "1個獲得金額のP/Oが10％よりも下回っています。1個獲得金額を下げてください。",
        (value) => {
          if (!value || !unitPriceJpy) return true
          if (value > unitPriceJpy / 0.1) return false
          return true
        },
      ),
    expectedConsumptionRate: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("正の値を入力してください。"),
    numOfBooths: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .positive("正の値を入力してください。"),
    boothCategory: yup.string(),
    machine: yup.string(),
    feeId: yup
      .number()
      .transform((value) => (isNaN(value) ? undefined : value)),
  })

type Props = {
  onSubmit: (data: PrizeMonthlyFormValues) => void
  prizeBoothCategories: PrizeBoothCategory[]
  prize?: Prize
  prizeDelivery?: PrizeDelivery
  prizeFees: PrizeFee[]
  machines: Machine[]
} & Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit">

export const PrizeMonthlyForm = forwardRef<HTMLFormElement, Props>(
  (
    {
      onSubmit,
      prizeBoothCategories,
      prize,
      prizeDelivery,
      prizeFees,
      machines,
      ...formProps
    },
    ref,
  ) => {
    const { orderCarton, machineInputDate } = prizeDelivery ?? {}
    const { unitPerCarton, unitPriceJpy } = prize ?? {}

    const {
      register,
      handleSubmit,
      control,
      formState: { errors },
    } = useFormContext<PrizeMonthlyFormValues>()

    const {
      // 一個獲得金額
      expectedCapturePrice,
      // 初週消化率
      expectedConsumptionRate,
      // ブース数
      numOfBooths,
    } = useWatch({ control })

    const expectedSalesPrice =
      (expectedCapturePrice &&
        expectedConsumptionRate &&
        orderCarton &&
        unitPerCarton &&
        getExpectedSalesPrice({
          expectedCapturePrice,
          expectedConsumptionRate,
          orderCarton,
          unitPerCarton,
        })) ||
      undefined

    const expectedPrizePdpb =
      (numOfBooths &&
        machineInputDate &&
        expectedSalesPrice &&
        getExpectedPrizePdpb({
          numOfBooths,
          machineInputDate,
          expectedSalesPrice,
        })) ||
      undefined

    return (
      <form {...formProps} onSubmit={handleSubmit(onSubmit)} ref={ref}>
        <Stack gap={2}>
          <Stack flex={1}>
            <Stack gap={1} flex={1}>
              <Typography variant="caption" color="gray.50">
                1個獲得金額
              </Typography>
              <Stack direction="row" flexGrow={1} alignItems="center">
                <TextField
                  type="number"
                  {...register("expectedCapturePrice", {
                    valueAsNumber: true,
                  })}
                  sx={{ flexGrow: 1 }}
                  error={!!errors.expectedCapturePrice}
                />
                <Typography sx={{ pl: 1, flexBasis: 56 }}>円</Typography>
              </Stack>
            </Stack>
            {!!errors.expectedCapturePrice && (
              <Stack direction="row" justifyContent="flex-end">
                <Typography
                  variant="body2"
                  sx={{ color: "error.main", pt: 1, pr: 3 }}
                >
                  {errors.expectedCapturePrice.message}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack gap={1} flex={1}>
            <Typography variant="caption" color="gray.50">
              見込みP/O
            </Typography>
            <Stack direction="row" flexGrow={1}>
              <Typography
                component="span"
                variant={expectedCapturePrice ? "h3" : "body1"}
              >
                {expectedCapturePrice && unitPriceJpy
                  ? round((unitPriceJpy / expectedCapturePrice) * 100, 1)
                  : "-"}
              </Typography>
              <Typography component="span" sx={{ pl: 0.5, flexBasis: 56 }}>
                %
              </Typography>
            </Stack>
          </Stack>

          <Stack flex={1}>
            <Stack gap={1} flex={1}>
              <Typography variant="caption" color="gray.50">
                初週消化率
              </Typography>
              <Stack direction="row" flexGrow={1} alignItems="center">
                <TextField
                  sx={{ flexGrow: 1 }}
                  type="number"
                  {...register("expectedConsumptionRate", {
                    valueAsNumber: true,
                  })}
                  error={!!errors.expectedConsumptionRate}
                />
                <Typography sx={{ pl: 1, flexBasis: 56 }}>%</Typography>
              </Stack>
            </Stack>
            {!!errors.expectedConsumptionRate && (
              <Stack direction="row" justifyContent="flex-end">
                <Typography
                  variant="body2"
                  sx={{ color: "error.main", pt: 1, pr: 3 }}
                >
                  {errors.expectedConsumptionRate.message}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack flex={1}>
            <Stack gap={1} flex={1}>
              <Stack>
                <Typography variant="caption" color="gray.50">
                  ブース展開数
                </Typography>
                {expectedPrizePdpb && expectedPrizePdpb >= 30000 && (
                  <Typography variant="body2">※多ブース展開推奨</Typography>
                )}
              </Stack>
              <Stack direction="row" flexGrow={1} alignItems="center">
                <TextField
                  sx={{ flexGrow: 1 }}
                  type="number"
                  {...register("numOfBooths", {
                    valueAsNumber: true,
                  })}
                  error={!!errors.numOfBooths}
                />
                <Typography sx={{ pl: 1, flexBasis: 56, whiteSpace: "nowrap" }}>
                  ブース
                </Typography>
              </Stack>
            </Stack>
            {!!errors.numOfBooths && (
              <Stack direction="row" justifyContent="flex-end">
                <Typography
                  variant="body2"
                  sx={{ color: "error.main", pt: 1, pr: 3 }}
                >
                  {errors.numOfBooths.message}
                </Typography>
              </Stack>
            )}
          </Stack>

          <Stack gap={1} flex={1}>
            <Typography variant="caption" color="gray.50">
              料金設定
            </Typography>
            <Stack flexGrow={1}>
              <Controller
                name="feeId"
                control={control}
                render={({ field }) => (
                  <SearchAutoComplete
                    items={prizeFees.map(({ id, fee, playCount }) => ({
                      label: playCount + "回" + fee + "円",
                      value: id,
                    }))}
                    {...field}
                  />
                )}
              />
            </Stack>
          </Stack>

          <Stack gap={2} flex={1}>
            <Stack gap={1} flex={1}>
              <Typography variant="caption" color="gray.50">
                ブース区分
              </Typography>

              <Controller
                name="boothCategory"
                control={control}
                render={({ field }) => (
                  <SearchAutoComplete
                    items={prizeBoothCategories.map(({ id, name }) => ({
                      label: name,
                      value: id,
                    }))}
                    {...field}
                  />
                )}
              />
            </Stack>

            <Stack gap={1} flex={1}>
              <Typography variant="caption" color="gray.50">
                投入予定機種
              </Typography>
              <Controller
                name="machine"
                control={control}
                render={({ field }) => (
                  <SearchAutoComplete
                    items={machines.map(({ name }) => ({
                      label: name,
                      value: name,
                    }))}
                    {...field}
                  />
                )}
              ></Controller>
            </Stack>
          </Stack>
        </Stack>
      </form>
    )
  },
)

PrizeMonthlyForm.displayName = "PrizeMonthlyForm"
