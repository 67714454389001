import { Fragment, useState } from "react"

import {
  Add,
  Edit,
  ErrorOutline,
  ReportProblemOutlined,
} from "@mui/icons-material"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import {
  Typography,
  Stack,
  TableHead,
  TableRow,
  Button,
  Link,
  IconButton,
} from "@mui/material"
import dayjs from "dayjs"
import {
  Link as RouterLink,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom"
import { useRecoilValue } from "recoil"

import { PrizeBooth, PrizeMeterRead } from "src/api/models"
import { execPrizePosReads, getPrizeMeterReads } from "src/api/prize-sales"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { LoadingBox } from "src/components/molecules/LoadingBox"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import {
  PrizeMeterReadDatePicker,
  prizeMeterReadSelectedDateState,
} from "src/components/organisms/prizes/PrizeMeterReadDatePicker"
import {
  PrizeMeterReadFilter,
  defaultSearchParams,
  PrizeMeterReadFilterSearchParams,
} from "src/components/organisms/prizes/PrizeMeterReadFilter"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { getBoothNameWithoutSeatNumber } from "src/domains/prizes/prizeBoothRepository"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { filterAccordionSearchState } from "src/recoil"
import { DateLabelString } from "src/types"
import {
  getUpdatableMeterReadDayjs,
  getRatioLabel,
  formatApiDate,
} from "src/utils"

export const PrizeMeterReadList: React.FC = () => {
  const { arcadeCd } = useParams()
  const [urlSearchParams] = useSearchParams()
  const editedDate = urlSearchParams.get("edited_date")
  const navigate = useNavigate()

  const selectedDate = useRecoilValue(prizeMeterReadSelectedDateState)
  const recoilSearchParams = useRecoilValue(filterAccordionSearchState)

  const searchParams: PrizeMeterReadFilterSearchParams =
    recoilSearchParams["prizeMeterReadsSearchParams"] ?? defaultSearchParams

  const isDisableEditByDate = dayjs(selectedDate).isBefore(
    getUpdatableMeterReadDayjs(),
  )

  const [isSubmitting, setIsSubmitting] = useState(false)
  const { submitPromises } = useSubmitting()
  const onClickPosRead = async () => {
    setIsSubmitting(true)
    arcadeCd &&
      (await submitPromises([
        {
          subject: "メーターリード結果の更新",
          showSuccessMessage: true,
          promise: async () => {
            await execPrizePosReads(arcadeCd)
          },
        },
      ]))
    setIsSubmitting(false)
  }

  return (
    <MainContentLayout
      title="プライズ売上情報の入力・更新"
      renderFilter={() => (
        <Stack gap={2}>
          <PrizeMeterReadDatePicker defaultValue={editedDate} />
          <Stack>
            <PrizeMeterReadFilter />
          </Stack>
        </Stack>
      )}
      renderContent={() => (
        <>
          {isSubmitting && <LoadingBox />}
          <Stack sx={{ flexDirection: "row", gap: 1, width: "100%", pb: 2 }}>
            <Button
              variant="contained"
              sx={{ px: 0, flex: 1 }}
              onClick={() =>
                navigate(`/arcades/${arcadeCd}/prizes/meterRead/csv`)
              }
              startIcon={<Add />}
              disabled={isDisableEditByDate}
            >
              CSV登録
            </Button>
            <Button
              variant="outlined"
              sx={{ px: 0, flex: 1 }}
              onClick={onClickPosRead}
              startIcon={<AutorenewIcon />}
              disabled={isSubmitting}
            >
              自動連携更新
            </Button>
          </Stack>

          {selectedDate && (
            <PrizeMeterReadListInner
              selectedDate={selectedDate}
              searchParams={searchParams}
            />
          )}
        </>
      )}
    />
  )
}

type PrizeMeterReadListInnerProps = {
  selectedDate: DateLabelString
  searchParams: PrizeMeterReadFilterSearchParams
}

const PrizeMeterReadListInner: React.FC<PrizeMeterReadListInnerProps> = ({
  selectedDate,
  searchParams,
}) => {
  const { arcadeCd } = useParams()
  const [urlSearchParams] = useSearchParams()
  const edited_date = urlSearchParams.get("edited_date")
  const broken_booths = urlSearchParams.getAll("broken_booths")

  const prizeMeterReadsReturn = useResource({
    subject: "メーターリード結果の取得",
    fetch: arcadeCd
      ? () =>
          getPrizeMeterReads(arcadeCd, { from: selectedDate, to: selectedDate })
      : undefined,
    recoilKey: `getPrizeMeterReads:${arcadeCd}:${selectedDate}`,
  }).resource
  const prizeMeterReads = prizeMeterReadsReturn?.data.booths || []
  const meterReadBoothElement = prizeMeterReads.filter(({ meters, booth }) => {
    if (searchParams.showOnlySoftEmpty !== "null") {
      if (
        searchParams.showOnlySoftEmpty === "true" &&
        meters.some((meter) => meter.softYen100CoinCount === undefined)
      ) {
        return false
      }
      if (
        searchParams.showOnlySoftEmpty === "false" &&
        meters.some((meter) => meter.softYen100CoinCount !== undefined)
      ) {
        return false
      }
    }
    if (searchParams.isAvailable !== "null") {
      if (searchParams.isAvailable === "true" && !booth.isAvailable)
        return false
      if (searchParams.isAvailable === "false" && booth.isAvailable)
        return false
    }
    if (searchParams.prizeBoothName) {
      const names = booth.boothName.split("_")
      if (names.length > 0 && !names[0].includes(searchParams.prizeBoothName))
        return false
    }
    if (searchParams.isThincaTerminal !== "null") {
      if (
        searchParams.isThincaTerminal === "true" &&
        !meters[0]?.thincaTerminalNumber
      )
        return false
      if (
        searchParams.isThincaTerminal === "false" &&
        !!meters[0]?.thincaTerminalNumber
      )
        return false
    }
    return true
  })

  if (!meterReadBoothElement) return null

  const tableDataSet = new Map<string, TableRowData>()

  meterReadBoothElement.forEach(({ booth, meters }) => {
    const meter = meters[0]

    if (!meter) {
      return
    }

    // シートごとのデータをブース単位にまとめる
    tableDataSet.set(
      getBoothNameWithoutSeatNumber(booth),
      new Map([
        ...Array.from(
          tableDataSet?.get(
            `${booth.machineName}-${booth.machineNumber
              .toString()
              .padStart(3, "0")}`,
          ) ?? [],
        ),
        [
          booth.seatNumber,
          {
            booth,
            meter,
          },
        ],
      ]),
    )
  })

  return (
    <Stack>
      {edited_date && broken_booths && broken_booths.length > 0 && (
        <Stack
          sx={{
            flexDirection: "row",
            mb: 3,
            p: 1,
            backgroundColor: "error.light",
            borderRadius: 1,
            gap: 1,
          }}
        >
          <ErrorOutline sx={{ color: "error.main" }} />
          <Typography color="error.main" variant="body2">
            プライズ機種名
            {broken_booths
              .map((booth_name) => {
                const mr = prizeMeterReads.find(
                  ({ booth }) => booth.boothName === booth_name,
                )
                if (!mr) return
                return `「${getBoothNameWithoutSeatNumber(mr.booth)}」ブース名「${mr.booth.seatNumber}P」`
              })
              .join("、")}
            を故障中にしました。
            {formatApiDate(dayjs(edited_date).add(1, "day"))}
            のソフト値の計算に必要な初期値を入力してください。
          </Typography>
        </Stack>
      )}
      <PrizeMeterReadListTable
        tableDataSet={tableDataSet}
        selectedDate={selectedDate}
      />
    </Stack>
  )
}

type TableRowData = Map<
  PrizeBooth["seatNumber"],
  { booth: PrizeBooth; meter: PrizeMeterRead }
>
type PrizeMeterReadListTableProps = {
  tableDataSet: Map<string, TableRowData> // boothNameWithoutSeatNumber, TableRowData
  selectedDate: DateLabelString
}
const PrizeMeterReadListTable: React.FC<PrizeMeterReadListTableProps> = ({
  tableDataSet,
  selectedDate,
}) => {
  const { arcadeCd } = useParams()
  const navigate = useNavigate()
  const isDisableEditByDate = dayjs(selectedDate).isBefore(
    getUpdatableMeterReadDayjs(),
  )

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 440px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        noMargin
        items={Array.from(tableDataSet)}
        stateKey="PrizeMeterReadListTable"
        stickyHeader
        header={
          <TableHead sx={{ whiteSpace: "nowrap" }}>
            <TableRow sx={{ th: { p: 1, textAlign: "center " } }}>
              <ExtTableCell border sticky fixedWidth={200} zIndex={100}>
                プライズ機種名
              </ExtTableCell>
              <ExtTableCell border>ブース名</ExtTableCell>
              <ExtTableCell border>
                シンカクラウド
                <wbr />
                連携
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                100円
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                500円
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                プライズ
                <wbr />
                (ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                100円
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                500円
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                プライズ
                <wbr />
                (アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={125}>
                P/O管理方法
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                見なしP/O
              </ExtTableCell>
              <ExtTableCell border fixedWidth={74}>
                故障
              </ExtTableCell>
              <ExtTableCell border fixedWidth={74}>
                撤去
              </ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={([boothNameWithoutSeatNumber, tableRowData]) => {
          const seats = Array.from(tableRowData)
          const numberOfSeats = seats.length
          const isShowAlert = seats.some(([, { meter }]) => {
            return (
              meter.thincaTerminalNumber == "" &&
              ((meter.softYen100CoinCount || 0) >= 300 ||
                (meter.softYen500CoinCount || 0) >= 300 ||
                (meter.softPayout || 0) >= 300)
            )
          })
          return (
            <Fragment key={boothNameWithoutSeatNumber}>
              {seats.map(([seatNumber, { booth, meter }], index) => {
                const isEditable = booth.isAvailable && !isDisableEditByDate
                return (
                  <TableRow key={seatNumber} sx={{ td: { p: 1 } }}>
                    {index === 0 && (
                      <>
                        <ExtTableCell
                          border
                          sticky
                          zIndex={99}
                          rowSpan={numberOfSeats}
                        >
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Stack
                              sx={{
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                              }}
                            >
                              {isShowAlert && (
                                <ReportProblemOutlined
                                  sx={{ color: "warning.main" }}
                                />
                              )}
                              <Stack
                                sx={{
                                  ...(isShowAlert && {
                                    color: "warning.main",
                                  }),
                                }}
                              >
                                {boothNameWithoutSeatNumber}
                              </Stack>
                            </Stack>

                            <Stack>
                              <IconButton
                                aria-label="edit"
                                color="primary"
                                disabled={!isEditable}
                                onClick={() =>
                                  isEditable &&
                                  navigate(
                                    `/arcades/${arcadeCd}/prizes/meterRead/edit/${boothNameWithoutSeatNumber}?selected_date=${selectedDate}`,
                                  )
                                }
                              >
                                <Edit fontSize="small" />
                              </IconButton>
                            </Stack>
                          </Stack>
                          {isShowAlert && (
                            <Typography variant="caption" color="warning.main">
                              ソフト値が大きい数値です。メーター値を再確認し、入力ミスの場合は修正してください
                            </Typography>
                          )}
                        </ExtTableCell>
                      </>
                    )}

                    <ExtTableCell border>
                      <Link
                        to={`/arcades/${arcadeCd}/prizes/meterRead/show/${booth.boothName}`}
                        underline="none"
                        component={RouterLink}
                      >
                        {seatNumber}P
                      </Link>
                    </ExtTableCell>
                    <ExtTableCell border>
                      {meter.thincaTerminalNumber ? "連携" : "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softYen100CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softYen500CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.softPayout ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.yen100CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.yen500CoinCount ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {meter.payout ?? "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ whiteSpace: "nowrap" }}>
                      {meter.payoutCategory === "payout_out_meter" &&
                        "Pアウトメータ"}
                      {meter.payoutCategory === "assumed_payout_rate" &&
                        "見なしP/O"}
                      {meter.payoutCategory === undefined && "-"}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ textAlign: "end" }}>
                      {getRatioLabel(meter.assumedPayoutRate)}
                    </ExtTableCell>
                    <ExtTableCell border sx={{ whiteSpace: "nowrap" }}>
                      {meter.isBroken ? (
                        <Typography color="error" variant="body2">
                          故障中
                        </Typography>
                      ) : (
                        "-"
                      )}
                    </ExtTableCell>
                    {index === 0 && (
                      <ExtTableCell
                        border
                        sx={{ whiteSpace: "nowrap" }}
                        rowSpan={numberOfSeats}
                      >
                        {!booth.isAvailable && "撤去済"}
                      </ExtTableCell>
                    )}
                  </TableRow>
                )
              })}
            </Fragment>
          )
        }}
      />
    </Stack>
  )
}
