import { useMemo } from "react"

import { Typography, Stack, TableRow, TableHead, Card } from "@mui/material"
import { useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import {
  PrizeBoothDailySale,
  PrizeBoothDailySales,
  calcPrizeBoothDailySales,
} from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import {
  formatApiDate,
  getDatesBetween,
  getDaysAgo,
  getJpDateLabel,
  getJpDateLabelColor,
  getRatioLabel,
  getToday,
} from "src/utils"

type PrizeWeeklyBoothSalesDetailsDateRangeLabelState = DateRangePickerDateLabel

const defaultDateRangeLabel = {
  start: getDaysAgo(30),
  end: getToday(),
}

const prizeWeeklyBoothSalesDetailsDateRangeLabelState =
  atom<PrizeWeeklyBoothSalesDetailsDateRangeLabelState>({
    key: "prizeWeeklyBoothSalesDetailsDateRangeLabelState",
    default: defaultDateRangeLabel,
  })

export const PrizeWeeklyBoothSalesDetails: React.FC = () => {
  const { boothName } = useParams()

  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    prizeWeeklyBoothSalesDetailsDateRangeLabelState,
  )

  return (
    <MainContentLayout
      title="ブース別の日ごと実績一覧 ブース詳細"
      subtitle={boothName}
      renderFilter={() => (
        <Card sx={{ p: 3 }}>
          <DateRangePicker
            dateRangeLabel={dateRangeLabel}
            setDateRangeLabel={setDateRangeLabel}
          />
        </Card>
      )}
      renderContent={() => <PrizeWeeklyBoothSalesDetailsInner />}
    />
  )
}

const PrizeWeeklyBoothSalesDetailsInner: React.FC = () => {
  const { arcadeCd, boothName } = useParams()
  const dateRangeLabel = useRecoilValue(
    prizeWeeklyBoothSalesDetailsDateRangeLabelState,
  )
  const { start, end } = dateRangeLabel

  const prizeBoothSalesReturn = useResource({
    subject: "ブースの売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: start, to: end, boothName })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}:${boothName}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const sales = useMemo(
    () => calcPrizeBoothDailySales(prizeBoothSales),
    [prizeBoothSales],
  )

  return (
    <PrizeWeeklyBoothSalesDetailsTable
      sales={sales}
      startDate={start}
      endDate={end}
    />
  )
}

interface PrizeWeeklyBoothSalesDetailsTableProps {
  sales: PrizeBoothDailySales
  startDate: string
  endDate: string
}

const PrizeWeeklyBoothSalesDetailsTable: React.FC<
  PrizeWeeklyBoothSalesDetailsTableProps
> = ({ sales, startDate, endDate }) => {
  const stateKey = "PrizeWeeklyBoothSalesDetailsTable"
  const { isAdmin } = useUserRole()

  const dates = getDatesBetween(startDate, endDate)
  const tableDataSet = useMemo(() => {
    const set = new Map<string, PrizeBoothDailySale>()
    sales.items.forEach((v) => set.set(v.recordedAt, v))
    return set
  }, [sales])

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 384px)",
      }}
    >
      <PaginatedTable
        scrollableY
        stickyHeader
        noMargin
        items={dates}
        stateKey={stateKey}
        header={
          <TableHead sx={{ th: { whiteSpace: "nowrap" } }}>
            <TableRow sx={{ th: { textAlign: "center" } }}>
              <ExtTableCell border fixedWidth={100}>
                日付
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100}>
                景品CD
              </ExtTableCell>
              <ExtTableCell border fixedWidth={200}>
                景品名
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100}>
                売上
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100}>
                P/O
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    border
                    header
                    word="払出個数"
                    rowSpan={2}
                  />
                  <AdminExtTableCell
                    border
                    header
                    word="景品単価"
                    rowSpan={2}
                  />
                  <AdminExtTableCell border header word="景品代" rowSpan={2} />
                  <AdminExtTableCell
                    border
                    header
                    word="当日ブースPDPB"
                    rowSpan={2}
                  />
                  <AdminExtTableCell
                    border
                    header
                    word="期間内ブースPDPB"
                    rowSpan={2}
                  />
                  <AdminExtTableCell header word="PDPB比率" rowSpan={2} />
                </>
              )}
            </TableRow>
          </TableHead>
        }
        renderRow={(date, index) => {
          const item = tableDataSet.get(formatApiDate(date))

          return (
            <TableRow
              key={index}
              sx={{
                td: {
                  px: 1,
                },
              }}
            >
              <ExtTableCell border sx={{ textAlign: "center" }}>
                <Typography
                  variant="body2"
                  sx={{ color: getJpDateLabelColor(date) }}
                >
                  {getJpDateLabel(date)}
                </Typography>
              </ExtTableCell>
              <ExtTableCell border>{item?.prize?.prizeCd ?? "-"}</ExtTableCell>
              <ExtTableCell border>
                {item?.prize?.prizeName ?? "-"}
              </ExtTableCell>
              <ExtTableCell border sx={{ textAlign: "right" }}>
                {item?.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell border sx={{ textAlign: "right" }}>
                {getRatioLabel(item?.payoutRate) ?? "-"}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item?.payout?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item?.prize?.unitPriceJpy?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item?.payoutPrice?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item?.pdpb?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={sales.total.pdpb?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    border
                    sx={{ textAlign: "end" }}
                    word={item?.pdpbRatio?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
