import {
  PrizeFloorMapPoint,
  PrizeToneFloorMapPoint,
  PrizeToneBoothInfo,
  PrizeDailyPlan,
  PrizeDailyPlansElement,
  PrizeBoothUnit,
  PrizeDeliveryElement,
} from "src/api/models"
import { PrizeDailySale } from "src/domains/prizes/prizeSalesRepository"

export type BaseFloorMapPoint = {
  id: PrizeFloorMapPoint["id"]
  name: PrizeFloorMapPoint["name"]
  boothNames: PrizeFloorMapPoint["boothNames"]
  topLeftX: PrizeFloorMapPoint["topLeftX"]
  topLeftY: PrizeFloorMapPoint["topLeftY"]
  bottomRightX: PrizeFloorMapPoint["bottomRightX"]
  bottomRightY: PrizeFloorMapPoint["bottomRightY"]
  amMachineNumber: Exclude<PrizeFloorMapPoint["amMachineNo"], undefined>
}

export type ToneFloorMapPoint = BaseFloorMapPoint & {
  pointBooths: PrizeToneBoothInfo[]
}

export const convertToneFloorMapPoint = (
  point: PrizeToneFloorMapPoint,
  booths: PrizeToneBoothInfo[],
): ToneFloorMapPoint => ({
  ...point,
  amMachineNumber: "",
  pointBooths: getPointBooths(point, booths),
})

export const getPointBooths = (
  point: PrizeToneFloorMapPoint,
  booths: PrizeToneBoothInfo[],
) => {
  return point.boothNames.flatMap((boothUnit) => {
    const { boothName, machineName } = boothUnit
    return booths.filter(
      (booth) =>
        booth.boothName === boothName && booth.machineName == machineName,
    )
  })
}

export type PrizePlanFloorMapPoint = BaseFloorMapPoint & {
  plans: PrizeDailyPlan[]
  hasPrizeDelivery: boolean
  prizeDailySale?: PrizeDailySale
}

export const convertPrizePlanFloorMapPoint = ({
  point,
  prizeDailyPlans,
  prizeDeliveries,
  prizeDailySales,
}: {
  point: PrizeFloorMapPoint
  prizeDailyPlans: PrizeDailyPlansElement[] | null
  prizeDeliveries: PrizeDeliveryElement[] | null
  prizeDailySales?: PrizeDailySale[]
}): PrizePlanFloorMapPoint => {
  const plans = point.boothNames.flatMap((boothUnit) => {
    const { machineName, machineNumber, seatNumber } = splitBoothUnit(boothUnit)
    return (prizeDailyPlans || [])
      .filter(
        (p) =>
          p.booth.machineName === machineName &&
          p.booth.machineNumber === machineNumber &&
          p.booth.seatNumber === seatNumber,
      )
      .map((p) => p.plan)
  })

  const hasPrizeDelivery = plans.some((plan) =>
    prizeDeliveries?.some(
      (delivery) => delivery.prize.prizeCd === plan.prize.prizeCd,
    ),
  )

  const prizeDailySale = prizeDailySales?.find((s) =>
    point.boothNames.some((boothUnit) => {
      const { machineName, machineNumber, seatNumber } =
        splitBoothUnit(boothUnit)
      return (
        s.booth.machineName === machineName &&
        s.booth.machineNumber === machineNumber &&
        s.booth.seatNumber === seatNumber
      )
    }),
  )

  return {
    ...point,
    amMachineNumber: point.amMachineNo || "",
    plans,
    hasPrizeDelivery,
    prizeDailySale,
  }
}

const boothUnitRegex = /([0-9]+)_([0-9]+)P/

export const splitBoothUnit = (
  boothUnit: PrizeBoothUnit,
): {
  machineName?: string
  machineNumber?: number
  seatNumber?: number
} => {
  const { machineName, boothName } = boothUnit
  const parts = boothUnitRegex.exec(boothName)
  if (parts) {
    return {
      machineName: machineName,
      machineNumber: !isNaN(Number(parts[1])) ? Number(parts[1]) : undefined,
      seatNumber: !isNaN(Number(parts[2])) ? Number(parts[2]) : undefined,
    }
  }
  return {}
}

export type PrizeDailyPlanFloorMapType = "plans" | "sales"
export type PrintPageSize = "a3" | "a4"

export const openPrintTab = ({
  arcadeCd,
  type,
  pageSize,
  date,
  area,
  autoPrint,
}: {
  arcadeCd: string | undefined
  type: PrizeDailyPlanFloorMapType
  pageSize: PrintPageSize
  date: string
  area?: string
  autoPrint?: boolean
}) =>
  window.open(
    `/arcades/${arcadeCd}/prizes/${type}/daily/floorMap/print?${new URLSearchParams(
      {
        pageSize,
        date,
        ...(area && { area }),
        ...(autoPrint && { auto: "1" }),
      },
    )}`,
    "_blank",
  )
