import { useState, useMemo, Suspense } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { ArrowDownwardRounded } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Box,
  Grid,
  CircularProgress,
  Card,
  Button,
  FormControl,
  FormHelperText,
  TextField,
  Divider,
  Typography,
} from "@mui/material"
import { AxiosResponse } from "axios"
import { Controller, SubmitHandler, useForm, useWatch } from "react-hook-form"
import { useParams } from "react-router-dom"
import { useSetRecoilState } from "recoil"
import * as Yup from "yup"

import { getMaterialMachines } from "src/api/material-machines"
import {
  putMaterialOperationMoveBetweenStorages,
  putMaterialOperationMoveToStorage,
} from "src/api/material-operation-move"
import { getMaterialOperationStocks } from "src/api/material-operation-stocks"
import { getMaterialStorages } from "src/api/material-storages"
import {
  Material,
  MaterialOperationShelfStock,
  MaterialOperationStock,
  MaterialShelf,
  MaterialStorage,
} from "src/api/models"
import { BackButton } from "src/components/atoms/BackButton"
import { AlertCaptionCard } from "src/components/molecules/AlertCaptionCard"
import { SearchAutoComplete } from "src/components/molecules/SearchAutoComplete"
import {
  getPlacementMoveFrom,
  MaterialStockMoveFrom,
  updateMaterialStockMoveFrom,
} from "src/components/templates/InventoryMaterialStockDetails"
import { useResource } from "src/hooks/useResource"
import { useSubmitting } from "src/hooks/useSubmitting"
import { snackbarErrorMessageState } from "src/recoil"

const updateStock = (
  materialCd: string,
  stocks: MaterialOperationStock[] | undefined,
  defaultStockMoveFrom: MaterialStockMoveFrom,
  shelfMoveTo: ShelfMoveTo | undefined,
) => {
  if (!stocks) {
    return {
      stockMoveFrom: defaultStockMoveFrom,
      shelfStocks: [],
    }
  }

  const stockMoveFrom = updateMaterialStockMoveFrom(
    defaultStockMoveFrom,
    stocks,
  )
  const shelfStock = stocks
    .flatMap((stock) =>
      stock.shelfStocks.filter(
        (stock) => stock.shelf.id === shelfMoveTo?.shelf.id,
      ),
    )
    .find((stock) => stock.shelfStock.materialCd === materialCd)
  return { stockMoveFrom, shelfStock }
}

export interface ShelfMoveTo {
  storage: MaterialStorage
  shelf: MaterialShelf
}

type MovePageState = "select" | "move" | "confirm"

interface InventoryMaterialMoveToShelfProps {
  material: Material
  stockMoveFrom: MaterialStockMoveFrom
  onFinish: () => void
}

export const InventoryMaterialMoveToShelf: React.FC<
  InventoryMaterialMoveToShelfProps
> = (props) => {
  const { arcadeCd } = useParams()
  const { material, stockMoveFrom: defaultStockMoveFrom, onFinish } = props
  const [movePageState, setMovePageState] = useState<MovePageState>("select")
  const [shelfMoveTo, setShelfMoveTo] = useState<ShelfMoveTo | undefined>(
    undefined,
  )
  const [oldShelfStock, setOldShelfStock] = useState<
    MaterialOperationShelfStock | undefined
  >(undefined)
  // TODO: 副帳票から取得するように変更する (要：API実装)
  const [note, setNote] = useState<string>("")

  const { materialCd } = material
  const { resource: resourceStocks, refetchForce: refetchStocks } = useResource(
    {
      subject: "在庫情報の取得",
      fetch: arcadeCd
        ? () => getMaterialOperationStocks(arcadeCd, { materialCd })
        : undefined,
      recoilKey: `getMaterialOperationStocks:${arcadeCd}:${materialCd}`,
      skip: movePageState === "select",
    },
  )
  const { stockMoveFrom, shelfStock } = useMemo(
    () =>
      updateStock(
        materialCd,
        resourceStocks?.data.stocks,
        defaultStockMoveFrom,
        shelfMoveTo,
      ),
    [materialCd, resourceStocks, defaultStockMoveFrom, shelfMoveTo],
  )

  const onSelected = (shelfMoveTo: ShelfMoveTo) => {
    setShelfMoveTo(shelfMoveTo)
    refetchStocks()
    setMovePageState("move")
  }
  const onMoved = ({ note }: { note: string }) => {
    setOldShelfStock(shelfStock)
    refetchStocks()
    setNote(note)
    setMovePageState("confirm")
  }
  const reset = () => {
    setShelfMoveTo(undefined)
    setOldShelfStock(undefined)
  }

  return (
    <Box>
      <Typography sx={{ mb: 1 }} variant="h2">
        {getPlacementMoveFrom(stockMoveFrom).name} から棚に移動
      </Typography>
      {movePageState === "select" && (
        <Typography variant="body2">移動先の棚を選択してください</Typography>
      )}

      <Box sx={{ mb: 2 }} />
      <Suspense
        fallback={
          <CircularProgress sx={{ display: "block", margin: "auto" }} />
        }
      >
        {movePageState === "select" && (
          <InventoryMaterialMoveToShelfSelectMenu
            stockMoveFrom={stockMoveFrom}
            onSelected={onSelected}
            onClose={() => onFinish()}
          />
        )}
        {movePageState === "move" && shelfMoveTo && (
          <InventoryMaterialMoveToShelfInputMenu
            material={material}
            stockMoveFrom={stockMoveFrom}
            shelfMoveTo={shelfMoveTo}
            shelfStock={shelfStock}
            onMoved={onMoved}
            onClose={() => {
              reset()
              setMovePageState("select")
            }}
          />
        )}
        {movePageState === "confirm" && shelfMoveTo && (
          <InventoryMaterialMoveToShelfConfirmMenu
            material={material}
            stockMoveFrom={stockMoveFrom}
            oldStockMoveFrom={defaultStockMoveFrom}
            shelfMoveTo={shelfMoveTo}
            shelfStock={shelfStock}
            oldShelfStock={oldShelfStock}
            note={note}
            onClose={() => {
              reset()
              onFinish()
            }}
          />
        )}
      </Suspense>
    </Box>
  )
}

export interface IdsMoveTo {
  storageId: MaterialStorage["id"]
  shelfId: MaterialShelf["id"]
}

export type InventoryMaterialMoveToShelfSelectFormInput = Partial<IdsMoveTo>

interface InventoryMaterialMoveToShelfSelectMenuProps {
  stockMoveFrom: MaterialStockMoveFrom
  onSelected: (shelfMoveTo: ShelfMoveTo) => void
  onClose: () => void
}

const InventoryMaterialMoveToShelfSelectMenu: React.FC<
  InventoryMaterialMoveToShelfSelectMenuProps
> = ({ stockMoveFrom, onSelected, onClose }) => {
  const { arcadeCd } = useParams()
  const { resource } = useResource({
    subject: "保管場所リストの取得",
    fetch: arcadeCd ? () => getMaterialStorages(arcadeCd) : undefined,
    recoilKey: `getMaterialStorage:${arcadeCd}`,
  })
  const storages = resource?.data.storages

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm<InventoryMaterialMoveToShelfSelectFormInput>()

  const value = {
    storageId: useWatch({ control, name: "storageId" }),
    shelfId: useWatch({ control, name: "shelfId" }),
  }
  const shelves =
    storages
      ?.find((storage) => storage.storage.id === value.storageId)
      ?.shelves?.filter((shelf) => shelf.isAvailable) || []
  const setErrorMessage = useSetRecoilState(snackbarErrorMessageState)

  const onSubmit: SubmitHandler<InventoryMaterialMoveToShelfSelectFormInput> = (
    data,
  ) => {
    const { storageId, shelfId } = data

    if (shelfId && shelfId === stockMoveFrom.operationShelfStock?.shelf.id) {
      setErrorMessage("移動前と同じ棚には移動できません")
      return
    }

    if (storageId && shelfId) {
      const e = storages?.find(({ storage }) => storage.id === storageId)
      const shelf = e?.shelves?.find((s) => s.id === shelfId)
      if (e && shelf) {
        onSelected({ storage: e.storage, shelf: shelf })
      } else {
        setErrorMessage("保管場所・棚が見つかりません")
      }
    }
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card sx={{ p: 2, mb: 3 }}>
          <Grid container sx={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4}>
              保管場所
            </Grid>
            <Grid item xs={8}>
              <FormControl fullWidth error={"storageId" in errors}>
                <Controller
                  name="storageId"
                  control={control}
                  render={({ field }) => (
                    <SearchAutoComplete
                      items={
                        storages?.map((storage) => ({
                          label: storage.storage.name,
                          value: storage.storage.id,
                        })) ?? []
                      }
                      {...field}
                    />
                  )}
                />
                {errors.storageId?.message && (
                  <FormHelperText>{errors.storageId?.message}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {shelves.length > 0 && (
              <>
                <Grid item xs={4} pt={1}>
                  棚
                </Grid>
                <Grid item xs={8} pt={1}>
                  <FormControl fullWidth error={"shelfId" in errors}>
                    <Controller
                      name="shelfId"
                      control={control}
                      render={({ field }) => (
                        <SearchAutoComplete
                          items={shelves.map((shelf) => ({
                            label: shelf.name,
                            value: shelf.id,
                          }))}
                          {...field}
                        />
                      )}
                    />
                    {errors.shelfId?.message && (
                      <FormHelperText>{errors.shelfId?.message}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </>
            )}
          </Grid>
        </Card>

        <Button
          variant="contained"
          type="submit"
          sx={{ mb: 3 }}
          fullWidth
          disabled={!value.storageId || !value.shelfId}
        >
          選択した棚に移動する
        </Button>

        <BackButton onClick={onClose}>戻る</BackButton>
      </form>
    </Box>
  )
}

export interface InventoryMaterialMoveToShelfInputFormInput {
  stock: number
  note?: string
}

interface InventoryMaterialMoveToShelfInputMenuProps {
  material: Material
  stockMoveFrom: MaterialStockMoveFrom
  shelfMoveTo: ShelfMoveTo
  shelfStock: MaterialOperationShelfStock | undefined
  onMoved: (v: { note: string }) => void
  onClose: () => void
}

const InventoryMaterialMoveToShelfInputMenu: React.FC<
  InventoryMaterialMoveToShelfInputMenuProps
> = ({
  material,
  stockMoveFrom,
  shelfMoveTo,
  shelfStock,
  onMoved,
  onClose,
}: InventoryMaterialMoveToShelfInputMenuProps) => {
  const { arcadeCd } = useParams()
  const { materialCd, unitPerCarton } = material

  const isFromMachine = !!stockMoveFrom.operationMachineStock
  const materialMachines = useResource({
    subject: "移動元材料機械情報の取得",
    fetch: arcadeCd ? () => getMaterialMachines(arcadeCd) : undefined,
    skip: !isFromMachine,
    recoilKey: `getMaterialMachines:${arcadeCd}`,
  }).resource?.data.materialMachines

  const placementMoveFrom = getPlacementMoveFrom(stockMoveFrom)
  const shelfMoveToStockCount = shelfStock?.shelfStock.stock || 0

  const validationSchema = Yup.object({
    stock: Yup.number()
      .required("必須です")
      .typeError("数値を入力してください")
      .min(0, "0以上の値を入力してください")
      .max(placementMoveFrom.stock, "移動前の在庫数を越えています"),
    note: Yup.string(),
  })

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    control,
  } = useForm<InventoryMaterialMoveToShelfInputFormInput>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      stock: placementMoveFrom.stock || 0,
      note: "",
    },
  })
  const value = {
    stock: Number(useWatch({ control, name: "stock" })) || 0,
  }

  const { submitPromises } = useSubmitting()

  const onSubmit: SubmitHandler<
    InventoryMaterialMoveToShelfInputFormInput
  > = async (data) => {
    const { operationShelfStock, operationMachineStock } = stockMoveFrom
    const putMove = (arcadeCd: string): Promise<AxiosResponse<void>> => {
      if (operationShelfStock) {
        const request = {
          from: { shelfId: operationShelfStock.shelf.id, stock: data.stock },
          to: { shelfId: shelfMoveTo.shelf.id, stock: data.stock },
          material: { materialCd },
          note: data.note ?? "",
        }
        return putMaterialOperationMoveBetweenStorages(arcadeCd, request)
      }
      if (operationMachineStock) {
        const materialMachine = materialMachines?.find((p) =>
          p.materialMachine.machineShelves?.some(
            (shelf) => shelf.id === operationMachineStock.machineShelf.id,
          ),
        )
        if (!materialMachine) throw new Error()
        const request = {
          from: [
            {
              machineShelfId: operationMachineStock.machineShelf.id,
              stock: data.stock,
            },
          ],
          to: { shelfId: shelfMoveTo.shelf.id, stock: data.stock },
          material: { materialCd },
          note: data.note ?? "",
        }
        return putMaterialOperationMoveToStorage(arcadeCd, request)
      }
      throw new Error()
    }

    arcadeCd &&
      (await submitPromises([
        {
          subject: "棚への移動",
          showSuccessMessage: true,
          promise: async () => {
            await putMove(arcadeCd)
            onMoved({ note: data.note ?? "" })
          },
        },
      ]))
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card sx={{ p: 2, mb: 3 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h2">{material.materialName}</Typography>
          </Grid>
          <Grid item xs={12} pb={1}>
            カートン入数：{unitPerCarton || 0} 個
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {/* 移動元 */}
          <Grid item xs={12}>
            {placementMoveFrom.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動させる数
            </Typography>
          </Grid>
          <Grid item xs={3} sx={{ textAlign: "right" }}>
            <Controller
              control={control}
              name={`stock`}
              render={({ field }) => (
                <TextField
                  {...field}
                  error={"stock" in errors}
                  helperText={errors.stock?.message || ""}
                  inputProps={{ inputMode: "numeric" }}
                  sx={{ textAlign: "right" }}
                />
              )}
            />
          </Grid>
          <Grid item xs={1} sx={{ textAlign: "right" }}>
            個
          </Grid>

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動前
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock}</strong>
            {" 個"}
          </Grid>

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動後
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock - value.stock}</strong>
            {" 個"}
          </Grid>

          {/* ディバイダー */}
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          {/* 移動先 */}
          <Grid item xs={12}>
            {shelfMoveTo.storage.name} {shelfMoveTo.shelf.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動する数
            </Typography>
          </Grid>

          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{value.stock}</strong> 個
          </Grid>

          <Grid item xs={0.5} />
          <Grid item xs={7.5}>
            <Typography variant="body2" color="text.secondary">
              移動前
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{shelfMoveToStockCount}</strong> 個
          </Grid>

          <Grid item xs={0.5} sx={{ pb: 2 }} />
          <Grid item xs={7.5} sx={{ pb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              移動後
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
            <strong>{shelfMoveToStockCount + value.stock}</strong> 個
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            <TextField
              label="備考"
              fullWidth
              error={"note" in errors}
              helperText={errors.note?.message}
              {...register("note")}
            />
          </Grid>
        </Grid>
      </Card>

      <LoadingButton
        variant="contained"
        type="submit"
        loading={isSubmitting}
        fullWidth
        sx={{ mb: 3, height: 44, lineHeight: 1.2, px: 0.5 }}
      >
        材料移動を保存
      </LoadingButton>

      <BackButton onClick={() => onClose()}>保存せず戻る</BackButton>
    </form>
  )
}

interface InventoryMaterialMoveToShelfConfirmMenuProps {
  material: Material
  stockMoveFrom: MaterialStockMoveFrom
  oldStockMoveFrom: MaterialStockMoveFrom
  shelfMoveTo: ShelfMoveTo
  shelfStock: MaterialOperationShelfStock | undefined
  oldShelfStock: MaterialOperationShelfStock | undefined
  note: string
  onClose: () => void
}

const InventoryMaterialMoveToShelfConfirmMenu: React.FC<
  InventoryMaterialMoveToShelfConfirmMenuProps
> = ({
  material,
  stockMoveFrom,
  oldStockMoveFrom,
  shelfMoveTo,
  shelfStock,
  oldShelfStock,
  note,
  onClose,
}: InventoryMaterialMoveToShelfConfirmMenuProps) => {
  const placementMoveFrom = getPlacementMoveFrom(stockMoveFrom)
  const oldPlacementMoveFrom = getPlacementMoveFrom(oldStockMoveFrom)
  const oldShelfStockNum = oldShelfStock?.shelfStock.stock || 0
  const shelfStockNum = shelfStock?.shelfStock.stock || 0

  return (
    <>
      <AlertCaptionCard label="下記の通り移動しました" />

      <Card sx={{ p: 2, mb: 3 }}>
        <Grid
          container
          sx={{ display: "flex", alignItems: "center" }}
          rowSpacing={1}
        >
          <Grid item xs={12}>
            <Typography variant="h2">{material.materialName}</Typography>
          </Grid>

          {/* 移動元 */}
          <Grid item xs={12}>
            {placementMoveFrom.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動した数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>
              {oldPlacementMoveFrom.stock - placementMoveFrom.stock}
            </strong>{" "}
            個
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              元
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{oldPlacementMoveFrom.stock}</strong> 個
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              現在
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{placementMoveFrom.stock}</strong> 個
          </Grid>

          {/* ディバイダー */}
          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              my: 1,
              display: "flex",
              justifyContent: "center",
              color: "neutral.400",
            }}
          >
            <ArrowDownwardRounded />
          </Grid>

          {/* 移動先 */}
          <Grid item xs={12}>
            {shelfMoveTo.storage.name} {shelfMoveTo.shelf.name}
          </Grid>

          <Grid item xs={8}>
            <Typography variant="body2" color="text.secondary">
              移動した数
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{shelfStockNum - oldShelfStockNum}</strong> 個
          </Grid>

          <Grid item xs={1} />
          <Grid item xs={7}>
            <Typography variant="body2" color="text.secondary">
              元
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right" }}>
            <strong>{oldShelfStockNum}</strong> 個
          </Grid>

          <Grid item xs={1} sx={{ pb: 2 }} />
          <Grid item xs={7} sx={{ pb: 2 }}>
            <Typography variant="body2" color="text.secondary">
              現在
            </Typography>
          </Grid>
          <Grid item xs={4} sx={{ textAlign: "right", pb: 2 }}>
            <strong>{shelfStockNum}</strong> 個
          </Grid>

          <Grid item xs={12} sx={{ mt: 2 }}>
            {note}
          </Grid>
        </Grid>
      </Card>

      <Button variant="outlined" fullWidth onClick={onClose} sx={{ mb: 3 }}>
        材料移動へ戻る
      </Button>

      <BackButton>検索画面へ戻る</BackButton>
    </>
  )
}
