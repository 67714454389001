import { useMemo } from "react"

import { Stack, Card, TableHead, TableRow } from "@mui/material"
import { useParams } from "react-router-dom"
import { atom, useRecoilState, useRecoilValue } from "recoil"

import { getPrizeBoothSales } from "src/api/prize-sales"
import { AdminExtTableCell } from "src/components/atoms/AdminExtTableHeaderCell"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { calcPrizeIpSales } from "src/domains/prizes/prizeSalesRepository"
import { useResource } from "src/hooks/useResource"
import { useUserRole } from "src/hooks/useUserRole"
import { getDaysAgo, getRatioLabel, getToday } from "src/utils"

type PrizeWeeklyIpSalesDateRangeLabelState = DateRangePickerDateLabel

const defaultDateRangeLabel = {
  start: getDaysAgo(7),
  end: getToday(),
}

const prizeWeeklyIpSalesDateRangeLabelState =
  atom<PrizeWeeklyIpSalesDateRangeLabelState>({
    key: "prizeWeeklyIpSalesDateRangeLabelState",
    default: defaultDateRangeLabel,
  })

export const PrizeWeeklyIpSales: React.FC = () => {
  const [dateRangeLabel, setDateRangeLabel] = useRecoilState(
    prizeWeeklyIpSalesDateRangeLabelState,
  )

  return (
    <MainContentLayout
      title="IP別の売上ランキング一覧"
      renderFilter={() => (
        <Card sx={{ p: 3 }}>
          <DateRangePicker
            dateRangeLabel={dateRangeLabel}
            setDateRangeLabel={setDateRangeLabel}
            startInputLabel="開始日"
            endInputLabel="終了日"
          />
        </Card>
      )}
      renderContent={() => <PrizeWeeklyIpSalesTable />}
    />
  )
}

const PrizeWeeklyIpSalesTable: React.FC = () => {
  const { arcadeCd } = useParams()
  const { isAdmin } = useUserRole()

  const dateRangeLabel = useRecoilValue(prizeWeeklyIpSalesDateRangeLabelState)
  const { start, end } = dateRangeLabel

  const prizeBoothSalesReturn = useResource({
    subject: "ブース別売上結果の取得",
    fetch: arcadeCd
      ? () => getPrizeBoothSales(arcadeCd, { from: start, to: end })
      : undefined,
    recoilKey: `getPrizeBoothSales:${arcadeCd}:${start}:${end}`,
  }).resource
  const prizeBoothSales = useMemo(
    () => prizeBoothSalesReturn?.data.sales || [],
    [prizeBoothSalesReturn],
  )

  const sales = useMemo(
    () => calcPrizeIpSales(prizeBoothSales),
    [prizeBoothSales],
  )

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 360px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        noMargin
        items={sales.items}
        stateKey="PrizeWeeklyIpSalesTable"
        header={
          <TableHead
            sx={{
              th: {
                textAlign: "center",
                whiteSpace: "nowrap",
                py: 1,
              },
            }}
          >
            <TableRow>
              <ExtTableCell sticky fixedWidth={80}>
                順位
              </ExtTableCell>
              <ExtTableCell sticky fixedWidth={240} border sx={{ left: 80 }}>
                キャラクター名
              </ExtTableCell>
              <ExtTableCell>売上</ExtTableCell>
              <ExtTableCell>構成比</ExtTableCell>
              <ExtTableCell>ブース数</ExtTableCell>
              <ExtTableCell>PDPB</ExtTableCell>
              <ExtTableCell>対ブース平均</ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell header word="払出個数" rowSpan={2} />
                  <AdminExtTableCell header word="景品代" rowSpan={2} />
                  <AdminExtTableCell header word="店舗全体PDPB" rowSpan={2} />
                </>
              )}
            </TableRow>
          </TableHead>
        }
        renderRow={(item) => {
          return (
            <TableRow
              key={"PrizeWeeklyIpSalesTable" + item.ipName}
              sx={{
                td: {
                  py: 1,
                },
              }}
            >
              <ExtTableCell sticky sx={{ textAlign: "center" }}>
                {item.ranking}
              </ExtTableCell>
              <ExtTableCell border sticky sx={{ left: 80 }}>
                {item.ipName || "(空白)"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.sales?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.countRatio)}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.count.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {item.pdpb?.toLocaleString() ?? "-"}
              </ExtTableCell>
              <ExtTableCell sx={{ textAlign: "end" }}>
                {getRatioLabel(item.pdpbRatio)}
              </ExtTableCell>

              {isAdmin && (
                <>
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payout?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={item.payoutPrice?.toLocaleString() ?? "-"}
                  />
                  <AdminExtTableCell
                    sx={{ textAlign: "end" }}
                    word={sales.total.pdpb?.toLocaleString() ?? "-"}
                  />
                </>
              )}
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
