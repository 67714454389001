import { yupResolver } from "@hookform/resolvers/yup"
import { ExpandCircleDown } from "@mui/icons-material"
import { LoadingButton } from "@mui/lab"
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material"
import {
  Controller,
  SubmitHandler,
  useForm,
  UseFormReturn,
} from "react-hook-form"
import * as Yup from "yup"

import {
  PrizeBooth,
  PrizeMeterRead,
  PrizeMeterReadPayoutCategoryEnum,
  RequestSoftMeterReads,
} from "src/api/models"
import { toNumber } from "src/utils/validation"

type PrizeMeterReadSoftMeter = Omit<
  RequestSoftMeterReads,
  "thincaTerminalNumber" | "recordedAt"
> & {
  useThincaTerminal: boolean
  thincaTerminalNumber?: string
  isOverridePrize?: boolean
}

type PrizeMeterReadSoftFormValues = {
  softMeters: PrizeMeterReadSoftMeter[]
}

const validationSchema = Yup.object({
  softMeters: Yup.array()
    .of(
      Yup.object({
        yen100CoinCount: Yup.number()
          .transform(toNumber)
          .required("必須です")
          .min(0, "0以上の値を入力してください"),
        yen500CoinCount: Yup.number()
          .transform(toNumber)
          .required("必須です")
          .min(0, "0以上の値を入力してください"),
        payoutCategory:
          Yup.string<PrizeMeterReadPayoutCategoryEnum>().required("必須です"),
        payout: Yup.number().when(
          [
            "payoutCategory",
            "useThincaTerminal",
            "isOverridePrize",
            "isBroken",
          ],
          {
            is: (
              payoutCategory: PrizeMeterReadSoftMeter["payoutCategory"],
              useThincaTerminal: PrizeMeterReadSoftMeter["useThincaTerminal"],
              isOverridePrize: PrizeMeterReadSoftMeter["isOverridePrize"],
              isBroken: PrizeMeterReadSoftMeter["isBroken"],
            ) =>
              payoutCategory ===
                PrizeMeterReadPayoutCategoryEnum.PayoutOutMeter &&
              (!useThincaTerminal || (useThincaTerminal && isOverridePrize)) &&
              !isBroken,
            then: (schema) =>
              schema
                .transform(toNumber)
                .required("必須です")
                .min(0, "0以上の値を入力してください"),
            otherwise: (schema) => schema.transform(toNumber),
          },
        ),
        assumedPayoutRate: Yup.number().when(["payoutCategory", "isBroken"], {
          is: (
            payoutCategory: PrizeMeterReadSoftMeter["payoutCategory"],
            isBroken: PrizeMeterReadSoftMeter["isBroken"],
          ) =>
            payoutCategory ===
              PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate && !isBroken,
          then: (schema) =>
            schema
              .transform(toNumber)
              .required("必須です")
              .min(10, "10%以上の値を入力してください")
              .max(100, "100%以下の値を入力してください"),
          otherwise: (schema) => schema.transform(toNumber),
        }),
        useThincaTerminal: Yup.boolean().required("必須です"),
        thincaTerminalNumber: Yup.string().when(
          ["useThincaTerminal", "isBroken"],
          {
            is: (
              useThincaTerminal: PrizeMeterReadSoftMeter["useThincaTerminal"],
              isBroken: PrizeMeterReadSoftMeter["isBroken"],
            ) => useThincaTerminal && !isBroken,
            then: (schema) => schema.required("必須です"),
            otherwise: (schema) => schema.transform((_) => ""),
          },
        ),
        isBroken: Yup.boolean().required("必須です"),

        // バリデーションに不要な項目
        boothName: Yup.string().required(),
        isOverridePrize: Yup.boolean(),
      }),
    )
    .required("必須です"),
})

export type OnSubmitPrizeMeterReadSoftForm =
  SubmitHandler<PrizeMeterReadSoftFormValues>

type PrizeMeterReadSoftFormProps = {
  items: { booth: PrizeBooth; meter: PrizeMeterRead }[]
  onSubmit: OnSubmitPrizeMeterReadSoftForm
  hideSetting?: boolean
}

export const PrizeMeterReadSoftForm: React.FC<PrizeMeterReadSoftFormProps> = ({
  items,
  onSubmit,
  hideSetting = true,
}) => {
  const useFormReturn = useForm<PrizeMeterReadSoftFormValues>({
    resolver: yupResolver<PrizeMeterReadSoftFormValues>(validationSchema),
    defaultValues: {
      softMeters: items.map(({ meter }) => ({
        boothName: meter.boothName,
        yen100CoinCount: meter.softYen100CoinCount || 0,
        yen500CoinCount: meter.softYen500CoinCount || 0,
        payoutCategory:
          meter.payoutCategory ||
          PrizeMeterReadPayoutCategoryEnum.PayoutOutMeter,
        payout: meter.softPayout,
        assumedPayoutRate: meter.assumedPayoutRate
          ? meter.assumedPayoutRate * 100
          : 30, // 初期値は30%
        useThincaTerminal: meter.thincaTerminalNumber !== "",
        thincaTerminalNumber: meter.thincaTerminalNumber,
        isBroken: meter.isBroken,
        isOverridePrize: false,
      })),
    },
  })

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useFormReturn

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack sx={{ gap: 2 }}>
        {items.map(({ booth }, index) => (
          <PrizeMeterReadSoftMeterItem
            key={booth.boothName}
            index={index}
            booth={booth}
            useFormReturn={useFormReturn}
            hideSetting={hideSetting}
          />
        ))}
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSubmitting}
          fullWidth
        >
          保存する
        </LoadingButton>
      </Stack>
    </form>
  )
}

type PrizeMeterReadSoftMeterItemProps = {
  booth: PrizeBooth
  useFormReturn: UseFormReturn<PrizeMeterReadSoftFormValues>
  index: number
  hideSetting: boolean
}

export const PrizeMeterReadSoftMeterItem: React.FC<
  PrizeMeterReadSoftMeterItemProps
> = ({ booth, useFormReturn, index, hideSetting }) => {
  const {
    formState: { errors },
    control,
    register,
    unregister,
    setValue,
    watch,
  } = useFormReturn
  const meterErrors = (errors.softMeters && errors.softMeters[index]) || {}

  const field = watch(`softMeters.${index}`)

  const { useThincaTerminal, payoutCategory, isBroken, isOverridePrize } = field

  const isAssumedPayoutRate =
    payoutCategory === PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate
  const isEnablePayoutOverride =
    !useThincaTerminal || (useThincaTerminal && isOverridePrize)

  return (
    <Stack gap={2} key={booth.boothName}>
      <Accordion sx={{ px: 1 }} defaultExpanded>
        <AccordionSummary
          expandIcon={
            <ExpandCircleDown
              sx={(theme) => ({ color: theme.palette.gray["40"] })}
            />
          }
        >
          <Typography variant="h2">{booth.seatNumber.toString()}P</Typography>
        </AccordionSummary>

        <AccordionDetails sx={{ py: 2 }}>
          <Stack sx={{ gap: 2 }}>
            {/* 故障状態 */}
            {(!hideSetting || (hideSetting && isBroken)) && (
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  故障
                </Typography>
                <Controller
                  name={`softMeters.${index}.isBroken`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      disabled={hideSetting}
                      error={"isBroken" in meterErrors}
                      onChange={(e) => {
                        setValue(
                          `softMeters.${index}.isBroken`,
                          e.target.value === "true",
                        )
                      }}
                    >
                      <MenuItem value="false">正常稼働</MenuItem>
                      <MenuItem value="true">故障中</MenuItem>
                    </Select>
                  )}
                />
                {meterErrors.isBroken?.message && (
                  <FormHelperText>
                    {meterErrors.isBroken.message}
                  </FormHelperText>
                )}
              </Stack>
            )}

            {/* シンカクラウド連携 */}
            {(!hideSetting || (hideSetting && useThincaTerminal)) && (
              <Stack gap={1}>
                <Typography
                  variant="body2"
                  sx={(theme) => ({ color: theme.palette.gray[50] })}
                >
                  シンカクラウド連携
                </Typography>
                <Controller
                  name={`softMeters.${index}.useThincaTerminal`}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...field}
                      error={"useThincaTerminal" in meterErrors}
                      onChange={(e) => {
                        const useThincaTerminal = e.target.value === "true"
                        setValue(
                          `softMeters.${index}.useThincaTerminal`,
                          useThincaTerminal,
                        )
                        if (useThincaTerminal) {
                          setValue(
                            `softMeters.${index}.thincaTerminalNumber`,
                            "",
                          )
                        } else {
                          // シンカ連携解除時にペイアウト関連のバリデーションをトリガーするため unregister
                          unregister(`softMeters.${index}.payout`)
                          unregister(`softMeters.${index}.assumedPayoutRate`)
                          // NOTE: defaultPayout は下記のケースのためにバリデーションできない
                          //  - 前日がプライズ上書きしているシンカ連携で今回連携を解除する
                          //  - ：シンカ連携OFFで前日の値あり
                          //  - ：通常のシンカ連携OFFと同じケースのため判定不可
                          //  - ：※ yup のバリデーションでは useThincaTerminal の変更前の値を参照できない
                        }
                      }}
                      disabled={isBroken || hideSetting}
                    >
                      <MenuItem value="true">連携あり</MenuItem>
                      <MenuItem value="false">連携なし</MenuItem>
                    </Select>
                  )}
                />
                {meterErrors.useThincaTerminal?.message && (
                  <FormHelperText>
                    {meterErrors.useThincaTerminal.message}
                  </FormHelperText>
                )}

                {useThincaTerminal && (
                  <TextField
                    {...register(`softMeters.${index}.thincaTerminalNumber`)}
                    sx={{ flexGrow: 1 }}
                    placeholder="端末識別番号を入力"
                    error={"thincaTerminalNumber" in meterErrors}
                    helperText={meterErrors.thincaTerminalNumber?.message || ""}
                    disabled={isBroken || hideSetting}
                  />
                )}
              </Stack>
            )}

            {/* P/O管理方法・見なしP/O */}
            {!hideSetting && !isBroken && (
              <>
                <Stack gap={1}>
                  <Typography
                    variant="body2"
                    sx={(theme) => ({ color: theme.palette.gray[50] })}
                  >
                    P/O管理方法
                  </Typography>
                  <Controller
                    name={`softMeters.${index}.payoutCategory`}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        error={"payoutCategory" in meterErrors}
                      >
                        <MenuItem
                          value={
                            PrizeMeterReadPayoutCategoryEnum.AssumedPayoutRate
                          }
                        >
                          見なしP/O
                        </MenuItem>
                        <MenuItem
                          value={
                            PrizeMeterReadPayoutCategoryEnum.PayoutOutMeter
                          }
                        >
                          Pアウトメータ
                        </MenuItem>
                      </Select>
                    )}
                  />
                </Stack>

                {isAssumedPayoutRate && (
                  <Stack gap={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({ color: theme.palette.gray[50] })}
                    >
                      見なしP/O
                    </Typography>
                    <Stack
                      sx={{
                        flexDirection: "row",
                        alignItems: "center",
                        gap: 1,
                      }}
                    >
                      <TextField
                        sx={{ flexGrow: 1 }}
                        {...register(`softMeters.${index}.assumedPayoutRate`)}
                        error={"assumedPayoutRate" in meterErrors}
                        helperText={
                          meterErrors.assumedPayoutRate?.message || ""
                        }
                        type="number"
                      />
                      <Typography
                        variant="body2"
                        sx={(theme) => ({ color: theme.palette.gray[50] })}
                      >
                        %
                      </Typography>
                    </Stack>
                  </Stack>
                )}
              </>
            )}

            {!isBroken && (
              <>
                {/* 100円コイン枚数 */}
                <Stack direction="row" gap={2}>
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({
                        color: theme.palette.gray[50],
                        pb: 1,
                      })}
                    >
                      100円
                      <br />
                      (ソフトM日計)
                    </Typography>
                    <TextField
                      sx={{ pb: 0.5 }}
                      {...register(`softMeters.${index}.yen100CoinCount`)}
                      error={"yen100CoinCount" in meterErrors}
                      helperText={meterErrors.yen100CoinCount?.message || ""}
                      type="number"
                      disabled={useThincaTerminal}
                    />
                  </Stack>
                </Stack>

                {/* 500円コイン枚数 */}
                <Stack direction="row" gap={2}>
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({
                        color: theme.palette.gray[50],
                        pb: 1,
                      })}
                    >
                      500円
                      <br />
                      (ソフトM日計)
                    </Typography>
                    <TextField
                      sx={{ pb: 0.5 }}
                      {...register(`softMeters.${index}.yen500CoinCount`)}
                      error={"yen500CoinCount" in meterErrors}
                      helperText={meterErrors.yen500CoinCount?.message || ""}
                      type="number"
                      disabled={useThincaTerminal}
                    />
                  </Stack>
                </Stack>

                {/* プライズ */}
                <Stack direction="row" gap={2}>
                  <Stack flex={1}>
                    <Typography
                      variant="body2"
                      sx={(theme) => ({
                        color: theme.palette.gray[50],
                        pb: 1,
                      })}
                    >
                      プライズ
                      <br />
                      (ソフトM日計)
                    </Typography>
                    <TextField
                      sx={{ pb: 0.5 }}
                      {...register(`softMeters.${index}.payout`)}
                      error={"payout" in meterErrors}
                      helperText={meterErrors.payout?.message || ""}
                      type="number"
                      disabled={isAssumedPayoutRate || !isEnablePayoutOverride}
                    />
                  </Stack>
                </Stack>
                {useThincaTerminal && !isAssumedPayoutRate && (
                  <Stack>
                    <Controller
                      name={`softMeters.${index}.isOverridePrize`}
                      control={control}
                      render={({ field: { value } }) => (
                        <FormControlLabel
                          label="プライズを上書きする"
                          control={
                            <Checkbox
                              checked={value}
                              onChange={(e) =>
                                setValue(
                                  `softMeters.${index}.isOverridePrize`,
                                  e.target.checked,
                                )
                              }
                            />
                          }
                        />
                      )}
                    />
                  </Stack>
                )}
              </>
            )}
          </Stack>
        </AccordionDetails>
      </Accordion>
    </Stack>
  )
}
