import {
  Csv,
  CsvRow,
  Machine,
  MeterReadBoothElement,
  RequestMeterReads,
  RequestMeterReadsPayoutCategoryEnum,
} from "src/api/models"
import { DateLabelString } from "src/types"
import { formatApiDate, getRecordedAtFromMeterReadCsv } from "src/utils"

enum columnIndex {
  boothName = 0,
  seatNumber = 2,
  recordedAt = 3,
  yen100CoinCount = 4,
  yen500CoinCount = 5,
  payout = 6,
}

type MeterReadCsvType = "ufoKey" | "usb"

type MeterReadMeta = {
  type: MeterReadCsvType
  fileName: string
  rowIndex: number
}

type MeterReadError = {
  code:
    | "invalid_booth_name"
    | "invalid_date"
    | "invalid_yen100coin_count"
    | "invalid_yen500coin_count"
    | "invalid_payout"
    | "different_date"
    | "no_previous_value"
    | "duplicated_data"
    | "unknown_meterRead"
  meta: MeterReadMeta
}

type MeterReadParseResult = {
  data: RequestMeterReads
  meta: MeterReadMeta
  errors?: MeterReadError[]
}

export function parseMeterReadCsvs({
  boothElements,
  selectedDate,
  machines,
  ufoCsvSet,
  usbCsvSet,
}: {
  boothElements: MeterReadBoothElement[] | undefined
  selectedDate: DateLabelString | undefined
  machines: Machine[]
  ufoCsvSet: Map<string, Csv>
  usbCsvSet: Map<string, Csv>
}) {
  const allResults = [
    ...parseMeterReadCsvSet(ufoCsvSet, "ufoKey", machines),
    ...parseMeterReadCsvSet(usbCsvSet, "usb", machines),
  ]

  const allErrors: MeterReadError[] = allResults.flatMap(
    (result) => result.errors || [],
  )

  const currentMeterReads = (boothElements || []).flatMap(
    (element) => element.meters,
  )

  const dupCheckMap = new Map<string, MeterReadMeta[]>()

  // 外部データや複数行にまたがるエラーチェック
  allResults
    .filter(({ errors }) => {
      // ブース名または日付が不正なデータは無視する
      return !errors?.some(
        ({ code }) => code === "invalid_booth_name" || code === "invalid_date",
      )
    })
    .forEach(({ data, meta }, i) => {
      // 選択中の日付と異なるエラー
      if (data.recordedAt !== selectedDate) {
        allErrors.push({ code: "different_date", meta })
      }

      // 前日値がないエラー
      const meterRead = currentMeterReads.find(
        ({ boothName, recordedAt }) =>
          boothName === data.boothName &&
          formatApiDate(recordedAt) === data.recordedAt,
      )
      if (meterRead) {
        if (
          (meterRead.prevYen100CoinCount == null &&
            meterRead.defaultYen100CoinCount == null) ||
          (meterRead.prevYen500CoinCount == null &&
            meterRead.defaultYen500CoinCount == null) ||
          (meterRead.prevPayout == null &&
            meterRead.defaultPayout == null &&
            meterRead.assumedPayoutRate == null)
        ) {
          allErrors.push({ code: "no_previous_value", meta })
        }
      } else {
        allErrors.push({ code: "unknown_meterRead", meta })
      }

      // request data の上書き
      if (allResults[i]?.data) {
        const updateData = data

        // 初期値が存在する場合は初期値を詰める
        if (meterRead?.defaultYen100CoinCount) {
          updateData.defaultYen100CoinCount = meterRead.defaultYen100CoinCount
        }
        if (meterRead?.defaultYen500CoinCount) {
          updateData.defaultYen500CoinCount = meterRead.defaultYen500CoinCount
        }
        // payoutCategoryの設定
        if (meterRead?.payoutCategory) {
          updateData.payoutCategory = meterRead.payoutCategory
          if (meterRead.payoutCategory === "payout_out_meter") {
            updateData.defaultPayout = meterRead.defaultPayout
          } else {
            updateData.assumedPayoutRate = meterRead.assumedPayoutRate
          }
        }
        allResults[i].data = updateData
      }

      // 同一ブース、同一日付のデータが重複していないかチェック
      const dupCheckKey = `${data.recordedAt}/${data.boothName}`
      dupCheckMap.set(dupCheckKey, [
        ...(dupCheckMap.get(dupCheckKey) || []),
        meta,
      ])
    })

  // 重複データエラー
  ;[...dupCheckMap.values()]
    .filter((arr) => arr.length > 1)
    .flat()
    .forEach((meta) => {
      allErrors.push({ code: "duplicated_data", meta })
    })

  const resultMap = {
    ufoKey: new Map<string, MeterReadParseResult[]>(),
    usb: new Map<string, MeterReadParseResult[]>(),
  }

  allResults.forEach(({ data, meta }) => {
    const { type, fileName, rowIndex } = meta

    // allErrors から該当ファイル、行のエラーを探して詰め直す
    const errors = allErrors.filter(
      (error) =>
        error.meta.type === type &&
        error.meta.fileName === fileName &&
        error.meta.rowIndex === rowIndex,
    )

    resultMap[type].set(fileName, [
      ...(resultMap[type].get(fileName) || []),
      { data, meta, ...(errors.length > 0 && { errors }) },
    ])
  })

  return resultMap
}

function parseMeterReadCsvSet(
  csvSet: Map<string, Csv>,
  type: MeterReadCsvType,
  machines: Machine[],
) {
  const machineSet = new Map<string, Machine>()
  machines.forEach((machine) => {
    machineSet.set(
      machine.usbMachineName !== "" ? machine.usbMachineName : machine.name,
      machine,
    )
  })

  return [...csvSet.entries()].flatMap(([fileName, csv]) =>
    csv.rows
      // 1行目はヘッダーなのでスキップする
      .slice(1)
      .map((csvRow, rowIndex) =>
        convertCsvRowToMeterReadElement(csvRow, machineSet, {
          type,
          fileName,
          rowIndex,
        }),
      ),
  )
}

function convertCsvRowToMeterReadElement(
  csvRow: CsvRow,
  machineSet: Map<string, Machine>,
  meta: MeterReadMeta,
): MeterReadParseResult {
  const { type } = meta

  const boothNameParts = (csvRow.columns[columnIndex.boothName] || "").split(
    "-",
  )
  const seatNumber = Number(csvRow.columns[columnIndex.seatNumber])
  const yen100CoinCount = Number(csvRow.columns[columnIndex.yen100CoinCount])
  const yen500CoinCount = Number(csvRow.columns[columnIndex.yen500CoinCount])
  const payout = Number(csvRow.columns[columnIndex.payout])
  const recordedAt = getRecordedAtFromMeterReadCsv(
    csvRow.columns[columnIndex.recordedAt] || "",
  )

  const errors: MeterReadError[] = []

  if (
    (type === "ufoKey" && boothNameParts.length != 2) ||
    (type === "usb" && boothNameParts.length != 3) ||
    isNaN(seatNumber)
  ) {
    errors.push({ code: "invalid_booth_name", meta })
  }

  if (isNaN(recordedAt.toDate().getDate())) {
    errors.push({ code: "invalid_date", meta })
  }

  if (isNaN(yen100CoinCount)) {
    errors.push({ code: "invalid_yen100coin_count", meta })
  }

  if (isNaN(yen500CoinCount)) {
    errors.push({ code: "invalid_yen500coin_count", meta })
  }

  if (isNaN(payout)) {
    errors.push({ code: "invalid_payout", meta })
  }

  let boothName = ""
  if (type === "ufoKey") {
    boothName = `${boothNameParts[0]}-${boothNameParts[1]}_${seatNumber}P`
  } else {
    const machine = machineSet.get(boothNameParts[0])
    if (machine) {
      boothName = `${machine.name}-${boothNameParts[1]}_${boothNameParts[2]}`
    } else {
      boothName = `${boothNameParts[0]}-${boothNameParts[1]}_${boothNameParts[2]}`
    }
  }

  return {
    data: {
      boothName: boothName,
      yen100CoinCount: yen100CoinCount,
      yen500CoinCount: yen500CoinCount,
      payoutCategory: RequestMeterReadsPayoutCategoryEnum.PayoutOutMeter, // CSV 登録は payout_out_meter で固定
      payout: payout,
      thincaTerminalNumber: "",
      isBroken: false,
      recordedAt: formatApiDate(recordedAt),
    },
    meta,
    ...(errors.length > 0 && { errors }),
  }
}

export function meterReadErrorText(error: MeterReadError) {
  switch (error.code) {
    case "invalid_booth_name":
      return "CSVの本体名または席番号がGiGONAVIのブース名と一致しません"
    case "invalid_date":
      return "日付のフォーマットが正しくありません"
    case "invalid_yen100coin_count":
      return "100円値が整数値になっていません"
    case "invalid_yen500coin_count":
      return "500円値が整数値になっていません"
    case "invalid_payout":
      return "プライズアウト値が整数値になっていません"
    case "different_date":
      return "日付が指定した日と一致しません"
    case "no_previous_value":
      return "前日値もしくは初期値が登録されていません"
    case "duplicated_data":
      return "同じブース、日付のデータが重複しています"
    case "unknown_meterRead":
      return "メーター値が見つかりません。エンジニアに問い合わせをしてください"
    default:
      return error.code
  }
}
