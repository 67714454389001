import { useCallback, useMemo, useState } from "react"

import { Typography, Stack, TableHead, TableRow, Card } from "@mui/material"
import dayjs from "dayjs"
import { useParams } from "react-router-dom"

import {
  PrizeBooth,
  PrizeMeterRead,
  PrizeMeterReadPayoutCategoryEnum,
} from "src/api/models"
import { getPrizeMeterReads } from "src/api/prize-sales"
import {
  DateRangePicker,
  DateRangePickerDateLabel,
} from "src/components/atoms/DateRangePicker"
import { ExtTableCell } from "src/components/atoms/ExtTableCell"
import { PaginatedTable } from "src/components/organisms/PaginatedTable"
import { MainContentLayout } from "src/components/templates/MainContentLayout"
import { useResource } from "src/hooks/useResource"
import {
  formatApiDate,
  getMonthEnd,
  getMonthStart,
  getRatioLabel,
} from "src/utils"

export const PrizeMeterReadShow: React.FC = () => {
  const { boothName } = useParams()
  const [dateRangeLabel, setDateRangeLabel] =
    useState<DateRangePickerDateLabel>({
      start: formatApiDate(getMonthStart()),
      end: formatApiDate(getMonthEnd()),
    })

  return (
    <MainContentLayout
      title="プライズ売上情報の入力・更新"
      subtitle={boothName}
      renderFilter={() => (
        <Card sx={{ p: 3 }}>
          <DateRangePicker
            dateRangeLabel={dateRangeLabel}
            setDateRangeLabel={setDateRangeLabel}
          />
        </Card>
      )}
      renderContent={() => (
        <PrizeMeterReadShowInner dateRangeLabel={dateRangeLabel} />
      )}
    />
  )
}

type PrizeMeterReadShowInnerProps = {
  dateRangeLabel: DateRangePickerDateLabel
}
const PrizeMeterReadShowInner: React.FC<PrizeMeterReadShowInnerProps> = ({
  dateRangeLabel: { start: from, end: to },
}) => {
  const { arcadeCd, boothName } = useParams()

  const prizeMeterReadsReturn = useResource({
    subject: "プライズ売上情報の取得",
    fetch:
      arcadeCd && boothName
        ? () => getPrizeMeterReads(arcadeCd, { from, to, boothName })
        : undefined,
    recoilKey: `getPrizeMeterReads:${arcadeCd}:${from}:${to}${boothName}`,
  }).resource
  const meterReadBoothElement = prizeMeterReadsReturn?.data.booths

  const tableData: TableRowData[] =
    useMemo(() => {
      if (
        !meterReadBoothElement ||
        !meterReadBoothElement[0] ||
        meterReadBoothElement.length !== 1
      ) {
        return []
      }
      const meterReadBooth = meterReadBoothElement[0]
      return Array.from(new Array(dayjs(to).diff(dayjs(from), "day") + 1)).map(
        (_, i) => {
          const date = dayjs(from).add(i, "day").format("YYYY-MM-DD")
          return {
            date: date,
            meter: meterReadBooth.meters.find(
              (meter) => dayjs(meter.recordedAt).format("YYYY-MM-DD") === date,
            ),
            amMachineNumber: meterReadBooth.booth.amMachineNumber,
          }
        },
      )
    }, [meterReadBoothElement, from, to]) || []

  return (
    <Stack>
      <PrizeMeterReadShowTable tableData={tableData} />
    </Stack>
  )
}

type TableRowData = {
  date: string
  meter?: PrizeMeterRead
  amMachineNumber: PrizeBooth["amMachineNumber"]
}
type PrizeMeterReadShowTableProps = {
  tableData: TableRowData[]
}

const PrizeMeterReadShowTable: React.FC<PrizeMeterReadShowTableProps> = ({
  tableData,
}) => {
  const getPayoutCategoryText = useCallback(
    (payoutCategory: PrizeMeterReadPayoutCategoryEnum | undefined) => {
      if (!payoutCategory) {
        return "-"
      } else if (payoutCategory === "payout_out_meter") {
        return "Pアウトメータ"
      } else if (payoutCategory === "assumed_payout_rate") {
        return "見なしP/O"
      } else {
        const _exhaustiveCheck: never = payoutCategory
        throw new Error("never")
      }
    },
    [],
  )

  return (
    <Stack
      sx={{
        maxHeight: "calc(100dvh - 380px)",
      }}
    >
      <PaginatedTable
        scrollableX
        scrollableY
        noMargin
        items={tableData}
        stateKey="PrizeMeterReadShowTable"
        stickyHeader
        header={
          <TableHead sx={{ whiteSpace: "nowrap" }}>
            <TableRow sx={{ th: { p: 1, textAlign: "center " } }}>
              <ExtTableCell border sticky fixedWidth={126} zIndex={100}>
                日付
              </ExtTableCell>
              <ExtTableCell border fixedWidth={100}>
                シンカクラウド
                <wbr />
                連携
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                故障
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                P/O管理方法
              </ExtTableCell>
              <ExtTableCell border fixedWidth={120}>
                見なしP/O
              </ExtTableCell>
              <ExtTableCell border fixedWidth={158}>
                100円(アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={158}>
                100円(ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={158}>
                500円(アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={158}>
                500円(ソフトM日計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={158}>
                プライズ(アナログM累計)
              </ExtTableCell>
              <ExtTableCell border fixedWidth={158}>
                プライズ(ソフトM日計)
              </ExtTableCell>
            </TableRow>
          </TableHead>
        }
        renderRow={({ date, meter }) => {
          const {
            isBroken,
            thincaTerminalNumber,
            payoutCategory,
            assumedPayoutRate,
            yen100CoinCount,
            yen500CoinCount,
            payout,
            softYen100CoinCount,
            softYen500CoinCount,
            softPayout,
          } = meter || {}
          return (
            <TableRow sx={{ td: { px: 1, py: 2 } }} key={date}>
              <ExtTableCell border sticky zIndex={99}>
                {date}
              </ExtTableCell>
              <ExtTableCell border>
                {thincaTerminalNumber && thincaTerminalNumber !== ""
                  ? "連携"
                  : "-"}
              </ExtTableCell>
              <ExtTableCell border>
                {isBroken ? (
                  <Typography color="error" variant="body2">
                    故障中
                  </Typography>
                ) : (
                  "-"
                )}
              </ExtTableCell>
              <ExtTableCell border>
                {getPayoutCategoryText(payoutCategory)}
              </ExtTableCell>
              <ExtTableCell border>
                {getRatioLabel(assumedPayoutRate)}
              </ExtTableCell>
              <ExtTableCell border>{yen100CoinCount ?? "-"}</ExtTableCell>
              <ExtTableCell border>{softYen100CoinCount ?? "-"}</ExtTableCell>
              <ExtTableCell border>{yen500CoinCount ?? "-"}</ExtTableCell>
              <ExtTableCell border>{softYen500CoinCount ?? "-"}</ExtTableCell>
              <ExtTableCell border>{payout ?? "-"}</ExtTableCell>
              <ExtTableCell border>{softPayout ?? "-"}</ExtTableCell>
            </TableRow>
          )
        }}
      />
    </Stack>
  )
}
